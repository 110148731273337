import React from "react";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { VideoModal } from "./VideoModal";
import { LazyLoadImage } from 'react-lazy-load-image-component';


export const VideoContent = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  
  return (
    <div className="introducing-container">
      <LazyLoadImage
        className="introducing-img-key"
        src={props.videoContent.homeKey.fields.file.url}
        alt="keys"
      />
      <h2 className="introducing-title">Introducing</h2>
      <LazyLoadImage
        className="introducing-turnkey-logo"
        src={props.videoContent.turnKeyLogo.fields.file.url}
        alt="turnkey logo"
      />
      <div className="image-with-text-container">
        <div
          className="image-with-text-img-wrapper pointer"
          onClick={()=>setModalShow(true)}
          onKeyUp={(event)=>{
            if(event.keyCode == 32 || event.keyCode == 13){
              setModalShow(true)
            }
          }}
          tabIndex="0"
          >
          <LazyLoadImage
            className="image-with-text-img"
            src={props.videoContent.videoImagePreview.fields.file.url}
            alt="Video Playback" 
          />
        </div>
        <div className="image-with-text-txt">
          <p dangerouslySetInnerHTML={{__html: documentToHtmlString(props.videoContent.description)}}>
              
          </p>
        </div>
      </div>
      <VideoModal 
        show={modalShow}
        onHide={() => setModalShow(false)}
        videoLink={props.videoContent.videoLink}
        isVideoModal={true}
      />
    </div>
  );
};
