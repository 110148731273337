module.exports = {

    /**
     * Scrolls to element
     * @param {String} sectionId Element Id to scroll to
     */
    scrollTo: function(sectionId) {
        let $section = document.getElementById(sectionId);
        let lastPos = 0;
        
        let i = setInterval(() => {
            let currentPos = window.scrollY || window.pageYOffset;

            if (currentPos === $section.offsetTop) {
                clearInterval(i)
            }
            
            if (currentPos < $section.offsetTop && lastPos <= currentPos) {
                window.scrollTo(0, currentPos + 10);
                lastPos = currentPos + 10;
            } else if (currentPos > $section.offsetTop && currentPos > 0) {
                // Scroll to top: Calculates how many pixels should substract
                let num = currentPos - 10 > $section.offsetTop ? 10 : currentPos - $section.offsetTop;
                window.scrollTo(0, currentPos - num);
                lastPos = currentPos - num;
            }

        }, 5);
    },

    uuidv4: () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },

    isIe: ()=>{
        let userAgent = window.navigator.userAgent;
        let isIe = userAgent.indexOf("MSIE");
        if(isIe > 0 ||  !!navigator.userAgent.match(/Trident.*rv\:11\./) ){
            return true;
        }else{
            return false;
        }
    },

    inIframe: ()=> {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    },

    isEdge: ()=>{
        let userAgent = window.navigator.userAgent;
        let isEdge = userAgent.indexOf("Edge/");
        if(isEdge > 0){
            return true;
        }else{
            return false;
        }
    }

}