import React,{useState} from "react";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {Link} from 'react-router-dom';
import Button from "../Button/Button";

const PriceTable = props => {
  return (
    <div className="price-table-container homepage">
      <h2 className="price-table-title">{props.price.header}</h2>
      {
        props.price.priceModel.map((price,index)=><Price key={index} price={price} />)
      }
      {
        props.isHome &&
        <Button pageMeta={props.pageMeta} to={props.price.button.fields.linkToPage.fields.url} title={props.price.button.fields.name} color="#212529">
          
        </Button>
      }
    </div>
  );
};

const Price = props => {
  const thePrice = props.price.fields;
  const [expand=true,setExpand] = useState(window.screen.width > 400);
  const [active,addActive]=useState(false);
  return (
    <div className="price-description-container">
      <div className="price-description-price">
        <h3 className="price-description-price-title heading-4">
          YOUR HOME PURCHASE PRICE
        </h3>
          <p className="price-description-price-amount">{thePrice.purchasePrice}</p>
        <span
          className={active === true? 'price-description-icon-toggle active' : 'price-description-icon-toggle'}
          tabIndex="0"
          aria-labelledby="Toggle Button"
          role="button"
          title="price table"
          aria-expanded={active === true?"false":"true"}
          aria-controls="accordionModal"
          onKeyUp={(event)=>{
            if(event.keyCode == 32 || event.keyCode == 13){
              if(expand){
                setExpand(false);
                addActive(!active)
              } else {
                setExpand(true);
                addActive(!active)
              }
            }
          }}
          onClick={()=>{
            if(expand){
              setExpand(false);
              addActive(!active)
            } else {
              setExpand(true);
              addActive(!active)
            }
          }}
        ></span>

      </div>
      <div className="price-description-more" style={(expand) ? {maxHeight: '1000px'} : {maxHeight:0}}>
        <div className="price-description-value">
          <p className="price-description-value-title">Package Value</p>
          <span className="price-description-value-amount">{thePrice.packageValue}</span>
        </div>
        <div className="price-description-info">
          <div 
            className="price-description-info-wrap" 
            dangerouslySetInnerHTML={{__html:documentToHtmlString(thePrice.description)}}>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceTable;
