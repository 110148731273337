import React from "react";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const RealEstate = (props) => {
  return (
    <div
      className="real-estate"
      style={ window.screen.width >= 768 ? {backgroundImage: `url(${props.realEstate.background.fields.file.url})`} : {} }
    >
      <div className="rich-text-block">
        <div className="heading-wrapper">
          <LazyLoadImage
            className=""
            src={props.realEstate.extraImage.fields.file.url}
            alt="couple with real estate agent"
          />
          <h2 className="heading-2">{props.realEstate.headline}</h2>
        </div>
        <div className="content-container">
          <div
            className="common-text-line-height"
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(props.realEstate.infoText)
            }}
          ></div>
        </div>
      </div>
      <div className="badges">
        {props.realEstate.kpo.map((kpi, index) => (
          <div className="badge-diamond-container" key={index}>
            <div className="badge-diamond-wrap">
              <span className="badge-diamond-value">
                {kpi.fields.badge}<span className="percentage">%</span>
                {
                    index === 0 &&
                    <span className="asterisk">**</span>
                }
              </span>
            </div>
            <p className="badge-diamond-text">
                {kpi.fields.label}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RealEstate;
