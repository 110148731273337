import React, { Component } from 'react';
import { KPI } from '../KPI/KPI';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {Link} from 'react-router-dom';
import ControlledCarousel from '../ControlledCarousel/ControlledCarousel';
import './header.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Button from '../Button/Button';


class Header extends Component {
    render() {
        const headerContent= this.props.headerContent
        return (
            <header className="header" style={{backgroundImage: `url(${headerContent.fields.background.fields.file.url})`}}>
                <LazyLoadImage className="header-logo" src={headerContent.fields.logo.fields.file.url} alt="turnkey logo" />
                <div className="text-with-icons">
                    <h1 className="text-with-icons-title" dangerouslySetInnerHTML={{__html: headerContent.fields.headline}}>
                        
                    </h1>
                    <div><span className="text-with-icons-img">
                        <LazyLoadImage  alt="sponsore" src={headerContent.fields.sponsore.fields.file.url} />
                    </span>
                </div>
                </div>
                <div className="how-it-works-container how-it-works-header">
                    
                        
                        <div className="mobile-careousel">
                            <ControlledCarousel kpi={headerContent.fields.kpo} />
                        </div>
                        <div className="three-column-grid">
                            {
                                headerContent.fields.kpo.map((kpi,index)=>(
                                    <KPI key={index} kpi={kpi.fields} />       
                                ))
                            }
                        </div>
                    
                </div>
                {this.props.headerContent.fields.button &&
                    // <Link title={this.props.headerContent.fields.button.fields.name} id="header-signup-cta" to={this.props.headerContent.fields.button.fields.linkToPage.fields.url} className="header-button primary-button primary-button">
                    //     {this.props.headerContent.fields.button.fields.name}
                    // </Link>
                    <Button 
                        pageMeta={this.props.pageMeta}
                        to={this.props.headerContent.fields.button.fields.linkToPage.fields.url}
                        title={this.props.headerContent.fields.button.fields.name}
                    />
                }
                <p className="header-disclaimer text-center"  dangerouslySetInnerHTML={{__html:documentToHtmlString(headerContent.fields.infoText)}}>
                </p>
            </header>
        );
    }
}

export default Header;