import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const FooterImage = (props) => {
  return (
    <div className="blade-container" style={{padding:0}}>
      <div className="footer-img-container">
        <img
          className="footer-image-element"
          src={props.url}
          alt="Footer home"
        />
      </div>
    </div>
  );
};

export default FooterImage;
