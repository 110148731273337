import React from 'react';
import './loading.scss';
import turnKeyLogo from './turnkey-image.png'
function Loading(props) {
    return (
        <div className="loading-page">
           <div className="loader"> <img className="image" src={turnKeyLogo} alt="" width="120" height="120" />
            <span style={{color:"#FFF"}}>Loading...</span></div>
        </div>
    );
}

export default Loading;