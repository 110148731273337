import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './video-modal.scss';

export const VideoModal=(props)=> {
    return (
      <div className="modal-wrapper">
        <Modal
        {...props}
        size="lg"
        aria-labelledby="video-modal"
        centered
        className={props.isVideoModal?"width50":""}
        role="dialog"
      >
        <Modal.Header>
            <span id="video-modal"></span>
            <span onClick={()=>props.onHide()} 
            onKeyUp={(e)=>{
              if(e.keyCode == 13 || e.keyCode == 32){
                props.onHide();
              }
            }}
            className="modal-close-btn close-btn" tabIndex="0" aria-label="close" role="button" ></span>
        </Modal.Header>
        <Modal.Body> 
            <iframe width="690" src={props.videoLink} />
        </Modal.Body>
      </Modal>
      </div>
    );
  }