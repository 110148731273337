import NotFoundPage from "./NotFoundPage";
import PropTypes from "prop-types";
import React from "react";
import { hot } from "react-hot-loader";
import MainContainer from "../container/MainContainer";
import Routes from "./Routes";
import 'bootstrap/dist/css/bootstrap.min.css';
// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.


class App extends React.Component {

  render() {
    return (
      <div>
          <Routes history={this.props.history} />
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.element
};

export default hot(module)(App);
