import React from 'react';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

function PrivacyAndTerms(props) {
    return (
        <div className="privacy-content" style={{textAlign:'left'}}>
            <h2 style={{textTransform: 'uppercase'}}>{props.content.fields.header}</h2><br/>
            <p
                dangerouslySetInnerHTML={{
                  __html: documentToHtmlString(props.content.fields.content)
                }}
            ></p>
        </div>
    );
}

export default PrivacyAndTerms;