import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import './modal.scss';

export const CommonModal=(props)=> {
  
  const clsName = props.isterms ? "width80" : "";
    return (
      <div className="modal-wrapper">
      {
        props.content &&
        <Modal
        {...props}
        size="lg"
        aria-labelledby="privacy-terms-modal"
        centered
        className={clsName}
        role="dialog"
        tabIndex="-1"
      >
        <Modal.Header>
            <h5 id="privacy-terms-modal">{props.content.fields.header}</h5>
            <span 
              style={{cursor:'pointer'}} 
              title="Close Button" 
              onClick={()=>props.onHide()} 
              className="modal-close-btn close-btn" 
              tabIndex="0" 
              aria-label="close" 
              onKeyUp={(e)=>{
                if(e.keyCode == 13 || e.keyCode == 32){
                  props.onHide()
                }
              }}
              role="button"/>
        </Modal.Header>
        <Modal.Body tabIndex="-1"> 
          <div tabIndex="-1" dangerouslySetInnerHTML={{
                  __html: documentToHtmlString(props.content.fields.content)
                }}>
            
          </div>
        </Modal.Body>
      </Modal>
      }
      </div>
    );
  }