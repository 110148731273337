import React, { Component,useState } from "react";
import { KPI } from "../KPI/KPI";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { SignupModal } from './SignupModal';
import InputMask from 'react-input-mask';
import _ from 'underscore';
import styled from 'styled-components';
import axios from 'axios';
import ControlledCarousel from "../ControlledCarousel/ControlledCarousel";
import './header-signup.scss';
import queryString from 'query-string';
import Utils from '../../../modules/utils';
import Loading from './loading.gif';


const Button = styled.button`
    background: transparent;
    border: none;
    font-size: 12px;
    display: inline-block;
    color: #FFF;
    font-size: 0.75em;
    font-weight: 300;
    text-decoration: underline;
    `

class HeaderSignup extends Component {
  constructor(props){
    super(props);
    this.state={
      loading: false,
      signupModalShow:false,
      termsModal: false,
      user:{
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        location: ''
      }
    }
  }


  updateField = (e,key) => {
    let form = _.clone(this.state.user);
    form[key] = e.target.value;
    this.setState({
      user: form
    })
  }

  getValidatedFields(){
    let fields = []
    if(!this.state.user.firstName){
      fields.push({name: 'First Name'})
    }
    if(!this.state.user.lastName){
      fields.push({name: 'Last Name'})
    }
    if(!this.state.user.phoneNumber){
      fields.push({name: 'Phone Number'})
    } else{
      var re = /^[+1]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
      if(!re.test(this.state.user.phoneNumber)){
        fields.push({name: 'Phone Number'})
      }
    }
    if(!this.state.user.email){
      fields.push({name: 'Email'})
    } else{
      var re = /\S+@\S+\.\S+/;
      if(!re.test(this.state.user.email)){
        fields.push({name: 'Email'})
      }
    }
    if(!this.state.user.location){
      fields.push({name: 'Location'})
    }
    
    let str = ""

    for(let i=0;i<fields.length;i++){
      let val = fields[i];
      let index = i
      str += val.name
      if(index >= 0){
        if(index == fields.length-2){
          str += " &";
        } else if(index !== fields.length-1){
          str += ", "
        }
      }
    }
    if(str.length > 0 ){
      //return "Please check the fields: " + str
    }
    return fields;
  }

  dataParser(formData) {

    const savedSearch = sessionStorage.getItem('searchStr');
    const currSearch = location.search;

    let searchStr = (currSearch && currSearch.trim().length > 0) ? currSearch : savedSearch;
    if(!searchStr || searchStr.trim().length == 0) searchStr = ''

    let parsedData = {
            'trace_id': Utils.uuidv4(),
            'partner': 'amazon',
            'processor': 'cartus',
            'data': {}
    },
    qParams = queryString.parse(searchStr);

    

    Object.keys(formData).forEach(x=>{
      if(x=='phoneNumber'){
        parsedData.data[x] = formData[x].replace(/[ ()_-]/g, "").substr(2).trim();
      } else {
        parsedData.data[x] = formData[x].trim();
      }
    })

    for (let key in qParams) {
        let value = qParams[key];
        key = key.toLowerCase();
        switch (key) {
            case 'campaignoperator':
            case 'campaign_operator':
            case 'utm_campaignoperator':
                key = 'utm_campaign_operator';
                break;

            case 'mastercampaign':
            case 'master_campaign':
            case 'utm_mastercampaign':
                key = 'utm_master_campaign';
                break;
        }

        switch (key) {
            case 'utm_lead_source':
            case 'utm_lead_id':
            case 'utm_campaign':
            case 'utm_content':
            case 'utm_medium':
            case 'utm_source':
            case 'utm_term':
            case 'utm_master_campaign':
            case 'utm_campaign_operator':
                // Parsing to camelCase
                let parsedKey = key.replace(/_([a-zA-Z0-1])/g, function (g) { return g[1].toUpperCase(); });
                parsedData.data[parsedKey] = value;
                break;
        }

    }

    if (!('utmSource' in parsedData.data)) {
        parsedData.data['utmSource'] = 'organic'
    }

    return parsedData;
} 


  signup(){
    this.setState({
      loading: true
    })
    axios({
      method: 'POST',
      data: this.dataParser(this.state.user),
      url: this.props.headerContent.fields.signupUrl
    }).then((resp)=>{
      this.setState({
        signupModalShow:true,
        loading: true
      })
    }).catch(()=>{
      // Show error
      this.setState({
        signupModalShow:true
      })
    })
  }

 


  render() {
    const headerContent = this.props.headerContent;
    const user = this.state.user;
    return (
      <>
        <div className="sign-up" id="sign-up">
          <div className="sign-up-form-wrapper">
            <div className="sign-up-form">
              <span className="sign-up-logo">
                <LazyLoadImage
                  className="header-logo"
                  src={headerContent.fields.logo.fields.file.url}
                  alt="turnkey logo"
                />
              </span>
                <div className="sign-up-form-row">
                  <div className={
                    _.find(this.getValidatedFields(),x=>x.name=="First Name") && this.state.firstNameValidated ? "error input-field sign-up-field sign-up-field-50" : "input-field sign-up-field sign-up-field-50"
                  }>
                    <label
                      className="input-field-label"
                      htmlFor="for-first-name"
                    >
                      First Name*
                    </label>
                    <input
                      autoComplete="off"
                      className="input-field-el heading-4"
                      placeholder="Please Enter First Name"
                      id="for-first-name"
                      type="text"
                      name="firstName"
                      onClick={()=>this.setState({firstNameValidated: true})}
                      onFocus={()=>this.setState({firstNameValidated: true})}
                      required=""
                      value={user.firstName}
                      maxLength="50"
                      aira-invalid="true"
                      onChange={(e)=>this.updateField(e,'firstName')}
                      aria-describedby="first-name-msg"
                    />
                    {
                      _.find(this.getValidatedFields(),x=>x.name=="First Name") && this.state.firstNameValidated &&
                      <div id="first-name-msg" className="invalid-msg">First name is required</div>
                    }
                  </div>
                  <div className={
                    _.find(this.getValidatedFields(),x=>x.name=="Last Name") && this.state.lastNameValidated ? "error input-field sign-up-field sign-up-field-50" : "input-field sign-up-field sign-up-field-50"
                  }>
                    <label
                      className="input-field-label"
                      htmlFor="for-last-name"
                    >
                      Last Name*
                    </label>
                    <input
                      autoComplete="off"
                      className="input-field-el heading-4"
                      placeholder="Please Enter Last Name"
                      id="for-last-name"
                      type="text"
                      value={user.lastName}
                      onClick={()=>this.setState({lastNameValidated: true})}
                      onFocus={()=>this.setState({lastNameValidated: true})}
                      name="lastName"
                      required=""
                      maxLength="50"
                      aria-describedby="email-id-msg"
                      onChange={(e)=>this.updateField(e,'lastName')}
                    />
                    {
                      _.find(this.getValidatedFields(),x=>x.name=="Last Name") && this.state.lastNameValidated &&
                      <div id="email-id-msg" className="invalid-msg">Last name is required</div>
                    }
                  </div>
                </div>
                <div className="sign-up-form-row">
                  <div className={
                    _.find(this.getValidatedFields(),x=>x.name=="Email") && this.state.emailIdValidate ? "error input-field sign-up-field sign-up-field-50" : "input-field sign-up-field sign-up-field-50"
                  }>
                    <label
                      className="input-field-label"
                      htmlFor="for-email"
                    >
                      Email ID
                    </label>
                    <input
                      autoComplete="off"
                      className="input-field-el heading-4"
                      placeholder="Please Enter Email"
                      id="for-email"
                      type="email"
                      name="email"
                      onClick={()=>this.setState({emailIdValidate: true})}
                      onFocus={()=>this.setState({emailIdValidate: true})}
                      value={user.email}
                      required=""
                      onChange={(e)=>this.updateField(e,'email')}
                      aria-describedby="email-id-msg"
                    />
                    {
                      _.find(this.getValidatedFields(),x=>x.name=="Email") && this.state.emailIdValidate &&
                      <div id="email-id-msg" className="invalid-msg">Invalid email id</div>
                    }
                    
                  </div>
                  

                  <div className={
                    _.find(this.getValidatedFields(),x=>x.name=="Phone Number") && this.state.phoneValidate ? "error input-field sign-up-field sign-up-field-50" : "input-field sign-up-field sign-up-field-50"
                  }>
                    <label
                      className="input-field-label"
                      htmlFor="for-phone"
                    >
                      Mobile Phone Number
                    </label>
                    <InputMask
                      autoComplete="off"
                      className="input-field-el heading-4"
                      placeholder="Please Enter Mobile Number"
                      id="for-phone"
                      type="tel"
                      name="tel"
                      required=""
                      onClick={()=>this.setState({phoneValidate: true})}
                      onFocus={()=>this.setState({phoneValidate: true})}
                      mask="+1(999)-999-9999"
                      aria-required="true"
                      value={user.phoneNumber}
                      onChange={(e)=>this.updateField(e,'phoneNumber')}
                      aria-live="mobile-number-msg"
                    />
                    {
                      _.find(this.getValidatedFields(),x=>x.name=="Phone Number") && this.state.phoneValidate &&
                      <div id="mobile-number-msg" className="invalid-msg">Invalid mobile number</div>
                    }
                    
                  </div>


                </div>
                <div className="sign-up-form-row">
                  <div className="select-field sign-up-field">
                    <label
                      className="select-field-label"
                      htmlFor="for-where-i'm-looking-to-buy-a-home"
                      style={{textAlign:'center'}}
                    >
                      Where I'm looking to buy a home*
                    </label>
                    <div className="select-field-wrapper">
                      <select
                        required=""
                        name="location"
                        aria-describedby="Select location"
                        className="select-field-el heading-6"
                        id="for-where-i'm-looking-to-buy-a-home"
                        value={user.location}
                        aria-required="true"
                        onChange={(e)=>this.updateField(e,'location')}
                      >
                        <option  value="" disabled="" hidden="">Metro Area*</option>
                        <option  value="Atlanta, GA">Atlanta, GA</option>
                        <option  value="Charlotte, NC">Charlotte, NC</option>
                        <option  value="Chicago, IL">Chicago, IL</option>
                        <option  value="Dallas–Fort Worth, TX">Dallas–Fort Worth, TX</option>
                        <option  value="Denver, CO">Denver, CO</option>
                        <option  value="Houston, TX">Houston, TX</option>
                        <option  value="Los Angeles, CA">Los Angeles, CA</option>
                        <option  value="Minneapolis-St. Paul, MN">Minneapolis-St. Paul, MN</option>
                        <option  value="Orlando, FL">Orlando, FL</option>
                        <option  value="Phoenix, AZ">Phoenix, AZ</option>
                        <option  value="Sacramento, CA">Sacramento, CA</option>
                        <option  value="San Francisco, CA">San Francisco, CA</option>
                        <option  value="Seattle, WA">Seattle, WA</option>
                        <option  value="Tampa, FL">Tampa, FL</option>
                        <option  value="Washington, DC">Washington, DC</option>
                        <option  value="Other">Other</option>
                      </select>
                      {
                      _.find(this.getValidatedFields(),x=>x.name=="Location") &&
                        <div style={{marginTop:'10px'}} id="location-msg" className="invalid-msg">Select a Metro area</div>
                      }
                    </div>
                  </div>
                </div>
                <button
                  aria-label="Sign up now"
                  type="submit"
                  disabled={ this.getValidatedFields().length > 0 }
                  className="primary-button input-submit "
                  onClick={this.signup.bind(this)}
                >
                  {
                    this.state.loading ?
                    <img style={{height:'30px'}} src={Loading} alt="Loading" />
                    :
                    'SIGN UP NOW'
                  }
                  
                </button>

              <div class="sign-up-disclaimer-wrapper">
                <span
                  className="sign-up-disclaimer heading-6 margin0"
                  dangerouslySetInnerHTML={{
                    __html: documentToHtmlString(headerContent.fields.description1)
                  }}
                ></span>
                <span>
                <Button className="terms" onClick={()=>{this.props.openModal(this.props.headerContent.fields.terms)}}>TurnKey Terms &amp; Conditions.</Button>
                <span
                  className="sign-up-disclaimer heading-6"
                  dangerouslySetInnerHTML={{
                    __html: documentToHtmlString(headerContent.fields.description2)
                  }}
                ></span>
                </span>
                <Button className="padding terms" onClick={()=>{this.props.openModal(this.props.headerContent.fields.privacy)}}>
                  Your privacy is assured.
                  </Button>

                </div>
              

              <div className="text-with-icons">
                <h1 className="text-with-icons-title">
                  {headerContent.fields.headline}
                </h1>
                <p className="text-with-icons-disclaimer">
                  *Amazon packages range from $1,000 to $5,000 in value
                  depending on your home’s purchase price. See details below.
                </p>
                <div>
                  <span className="text-with-icons-img">
                    <LazyLoadImage
                     alt="sponsor logo" aria-label="sponsor logo" src={headerContent.fields.sponsore.fields.file.url}
                     />
                  </span>
                </div>
              </div>
              <p className="sign-up-disclaimer-info">
                For more information and program details, call{" "}
                <a href={`tel:${headerContent.fields.contactNo}`}>
                  {headerContent.fields.contactNo}
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* <div className="how-it-works-container how-it-works-form">
          <h2 className="how-it-works-title">How It Works</h2>
          <div className="three-column-grid" style={{ maxWidth: "90%",display:'table !important' }}>
            {headerContent.fields.kpo.map((kpi, index) => (
              <KPI key={index} kpi={kpi.fields} />
            ))}
          </div>
        </div> */}
        <div className="how-it-works-container how-it-works-header signup-header" style={{backgroundColor:"#FFF"}}>
                    <h2 className="how-it-works-title">How It Works</h2>

                    <div className="mobile-careousel">
                        <ControlledCarousel kpi={headerContent.fields.kpo} />
                    </div>
                    <div className="three-column-grid">
                        {
                            headerContent.fields.kpo.map((kpi,index)=>(
                                <KPI key={index} kpi={kpi.fields} />       
                            ))
                        }
                    </div>
                
            </div>
        {this.state.signupModalShow &&
        <SignupModal 
          show={this.state.signupModalShow}
          user={this.state.user}
          onHide={() => {
            this.setState({
              signupModalShow:false
            });
          }}
        />}
      </>
    );
  }
}

export default HeaderSignup;
