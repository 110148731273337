import React, { Component } from 'react';
import Container from './Container';
import { CommonModal } from '../common/Modal/Modal';

class Page extends Component {

    state = {
        showModal: false,
        content: null
    }

    openModal(fields){
        this.setState({
            content: fields,
            showModal: true
        })
    }

    componentDidMount(){
        document.title = this.props.page.fields.title;
        setTimeout(()=>{
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },0)
    }

    render() {
        return (
            <div className="page-contentful">
                    <Container 
                        columnGrid={this.props.page.fields.containers[0].fields.columnGrid}
                        containers={this.props.page.fields.containers} 
                        openModal={this.openModal.bind(this)}
                        isHome={this.props.page.fields.isHome}
                        pageMeta={this.props.pageMeta}
                    />
                {
                    <CommonModal
                        show={this.state.showModal}
                        content={this.state.content}
                        onHide={() => this.setState({showModal: false, content: null})}
                        isterms={true}s
                        pageMeta={this.props.pageMeta}
                    />
                }
            </div>
        );
    }
}

export default Page;