import React from "react";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {Link} from 'react-router-dom';
import Button from "../Button/Button";

const Faq = props => {
  return (
    <div className="faqs" id="faqs">
      <div className="faqs-wrapper">
        <div className="faqs-info">
        <h2 className="faqs-title heading-2">{props.faq.title}</h2>
        </div>

        <div className="faqs-accordion-container">
          { props.faq.question.map((question,index)=>(
            <div className="accordion-item active" key={index}>
              <p className="accordion-item-trigger heading-4">
                {question.fields.header}
              </p>
              <div className="accordion-item-content common-text-light">
                <p dangerouslySetInnerHTML={{__html:documentToHtmlString(question.fields.content)}}>
                  
                </p>
              </div>
            </div>
          ))
          }
        </div>
      </div>
      <div className="text-with-button faqs-text-button">
        <span className="heading-4">{props.faq.inspireText}</span>
        {props.faq.button &&
        <Button color="#222" pageMeta={props.pageMeta} to={props.faq.button.fields.linkToPage.fields.url} title={props.faq.button.fields.name} className="primary-button button customised-bullet">
              
        </Button>
        }
      </div>
    </div>
  );
};

export default Faq;
