import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const ImageBlocks = props => {
    return (
        <div className="free-amazon-benefits">
            <div className="mosaic-images"><div className="first-row row">
                    {
                        props.imageBlocks.careoselImages.map((careosel,index)=>(
                            index < 2 &&
                            <LazyLoadImage key={`img-block-${index}`} className="mosaic-tile" src={careosel.fields.file.url} alt={careosel.fields.title}/>
                        ))
                    }
                </div>
                <div className="second-row row">
                        {
                            props.imageBlocks.careoselImages.map((careosel,index)=>(
                                index > 1 &&
                                <LazyLoadImage key={`img-blocks-${index}`} className="mosaic-tile" src={careosel.fields.file.url} alt={careosel.fields.title}/>
                            ))
                        }
                </div> 
            </div>
            <div className="overlap-image-with-text">
                <LazyLoadImage className="overlap-image" src={props.imageBlocks.horizontalImage.fields.file.url} alt="Amazon smart home products"/>
            </div>
        </div>
    );
};


export default ImageBlocks;