import React from 'react';
import Header from '../common/Header/Header';
import { VideoContent } from '../common/VideoContent/VideoContent';
import SimpleContent from '../common/SimpleContent/SimpleContent';
import RealEstate from '../common/RealEstate/RealEstate';
import ImageBlocks from '../common/ImageBlocks/ImageBlocks';
import PriceTable from '../common/PriceTable/PriceTable';
import Faq from '../common/Faq/Faq';
import Footer from '../common/Footer/Footer';
import HeaderSignup from '../common/HeaderSignup/HeaderSignup';
import HeadBox from '../common/HeadBox/HeadBox';
import PrivacyAndTerms from '../common/PrivacyAndTerms/PrivacyAndTerms';
//import { VideoContent } from 'VideoContent';



function Comp(props) {
    return (
        <div className="blade-container">
            {
                switchRender(
                    props.data.sys.contentType.sys.id,
                    props.data,
                    props.openModal,
                    props.isHome,
                    props.pageMeta
                )
            }
        </div>
    );
}

const switchRender = (type,data,openModal,isHome,pageMeta) => {

    switch(type){
        case 'header': 
            return <Header headerContent={data} pageMeta={pageMeta} />
        case 'videoContent':
            return <VideoContent videoContent={data.fields} pageMeta={pageMeta} />
        case 'simpleContent':
            return <SimpleContent content={data.fields} pageMeta={pageMeta} />
        case 'realEstate':
            return <RealEstate realEstate={data.fields} pageMeta={pageMeta} />
        case 'imageBlocks':
            return <ImageBlocks imageBlocks={data.fields} pageMeta={pageMeta}/>
        case 'price':
            return <PriceTable isHome={isHome} showButton={true} pageMeta={pageMeta} price={data.fields} />
        case 'faq':
            return <Faq pageMeta={pageMeta} faq={data.fields} />
        case 'footer':
            return <Footer isHome={isHome} openModal={openModal} pageMeta={pageMeta} footer={data.fields} />
        case 'signupHeader':
            return <HeaderSignup openModal={openModal} pageMeta={pageMeta} headerContent = {data} />
        case 'boxSection':
            return <HeadBox content = {data} />
        case 'termsAndPrivacy':
            return <PrivacyAndTerms content = {data} />
    }
}

export default Comp;