import easy from './data.json';
import _ from 'underscore';
// Function to create data for the page
export const createData = (data,websiteId) => {
    //data = easy;

    const site = _.find(data.items, (x)=>{
        return x.sys.id === websiteId
    });

    let pages = site.fields.pages;

    for(let i = 0; i < pages.length; i++){
        const currentPageDetails = _.find(data.items,(x)=>x.sys.id === pages[i].sys.id)
        pages[i] = currentPageDetails;
        theRecussion(
            currentPageDetails.fields.containers,
            data.items
        )
    }
    return pages;
}


const theRecussion = (contents,entries) => {
    for(let i = 0; i < contents.length;i++){
        // find the content
        const obj = _.find(entries,(x)=>x.sys.id === contents[i].sys.id);
        if(obj){
            contents[i] = obj;
            if(contents[i].fields.containers){
                theRecussion(obj.fields.containers,entries)
            }
        }
    }
}