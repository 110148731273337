import React, { Component,useState } from 'react';
import './careosel.scss';
import Carousel from 'react-bootstrap/Carousel'
import { KPI } from '../KPI/KPI';

function ControlledCarousel(props) {
    const [index, setIndex] = useState(0);
    const [direction, setDirection] = useState(null);
  
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
      setDirection(e.direction);
    };
  
    return (
      <Carousel activeIndex={index} direction={direction} onSelect={handleSelect} interval='2000'>
        {
          props.kpi.map((kp,index)=>(
            <Carousel.Item key={index} interval='null'>
              <KPI key={index} kpi={kp.fields} />
            </Carousel.Item>
          ))
        }
       
      </Carousel>
    );
  }

export default ControlledCarousel;

{/* <Carousel.Item>
          <Carousel.Caption>
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption>
        </Carousel.Item> */}