import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './SignupModal.scss';
import { Col } from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';

export const SignupModal = (props) => {
    return (
        <div className="modal-wrapper">
            <Modal
                {...props}
                size="lg"
                centered
            >
                <Modal.Body>
                    <div class="modal-container" aria-label="Thank you">
                        <div className="row">
                            <div className="col-lg-3">
                                <img alt={"TurnKey Logo"} src={require('./Home.svg')} />
                                <img  alt="Home success" src={require('./Home2.svg')} />
                            </div>
                            <div className="col-lg-9">
                                <h2 style={{fontSize:'2.5em'}} class="heading-2">You’re All Set, {props.user.firstName}!</h2>
                                <p style={{paddingTop:20,paddingBottom:20,fontSize:'16px'}}>Thanks for enrolling in TurnKey. You will receive a text message and then we will give you a call to match and personally introduce you to your TurnKey Agent.</p>
                                <Link onClick={props.onHide} to="/" class="primary-button close-button" role="button">
                                    Close
                                </Link>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}