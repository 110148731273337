import React from 'react';
import { Link } from 'react-router-dom';
import './button.scss';
import _ from 'underscore';


function Button(props) {
    const privacyPage = _.find(props.pageMeta,(pm)=>pm.pageName == "Privacy Policy");
    return (
        <>
            <Link title={props.title} target={
                window.location.hostname == "www.amazon.com" ?
                "_blank"
                :
                "_self"
            } className={props.className} to={props.to}>
                {props.title}
            </Link>
            {
                window.location.hostname == "www.amazon.com" &&
                <p className="header-disclaimer-goto" style={{marginBottom:'40px',color: props.color, fontSize: props.fontSize,width:props.width}}>
                    By clicking theGet started button you will leave Amazon.com and go to Realogy web page. All information you share will be subjected to Realogies
                    { privacyPage ?
                        <a className="privacy-link terms" href={privacyPage.url} target="_blank"> Privacy policy</a>
                        :
                        <span>Privacy policy page is missing</span>
                    }
                </p>
            }
        </>
    );
}

Button.defaultProps = {
    color: "#FFF",
    fontSize: "12px",
    width: "100%",
    className:"header-button primary-button primary-button"
}

export default Button;