import { cloneDeep } from "lodash";
import * as types from "../constants/actionTypes";

export const appReducer = (state = {}, action = {}) => {
  let newState = {};
  switch (action.type) {
    case types.GET_ENTRIES: {
      newState = cloneDeep(state);
      newState.entries = action.payload;
      return newState;
    }
    default:
        return newState
  }
}