import React from 'react';

function HeadBox(props) {
    return (
        <div style={props.content.fields.style}>
            
        </div>
    );
}

export default HeadBox;