import React, { Component } from "react";
import {  Route, Switch } from "react-router-dom";
import * as contentful from "contentful";
import NotFoundPage from "./NotFoundPage";
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from "../actions/";
import { createData } from "../services/Service";
import { conf } from "../Config";
import Page from '../components/Page/Page';
import _ from 'underscore';
import Loading from "./Loading/Loading";

class Routes extends Component {
    state = {
        pages: null,
        metaData: null,
        pageMeta: null
    }
    componentDidMount(){
    const client = contentful.createClient(
        conf.creds
    )
     
    client.getEntries() //{'sys.id':'1ouGAufxF0MwtkesQOft7H',include: 10}
      .then((response) => {
        // var t0 = performance.now();
        const pages = createData(response,conf.websiteId)
        let pageMeta = [];
        for(let i = 0; i < pages.length ; i++){
          pageMeta.push({
            url: pages[i].fields.url,
            title: pages[i].fields.title,
            pageName: pages[i].fields.pageName,
            isHome: pages[i].fields.isHome,
            description: pages[i].fields.description
          })
        }
        this.setState({
            pages: pages,
            metaData: _.find(response.items,(x)=>x.sys.id == conf.websiteId),
            pageMeta: pageMeta
        });
        var t1 = performance.now();
        // console.log("Call to doSomething took " + (t1 - t0) + " milliseconds.");
      })
      .catch(console.error)
    
     
  }
  render() {
    return (
      <>
        { this.state.pages ?
          <div style={this.state.metaData.fields.theme}>
            <Switch>
              { this.state.pages.map((page,index)=>(
                        <Route 
                          key={index} exact path={page.fields.isHome ? "/" : page.fields.url} 
                          component={()=><Page pageMeta={this.state.pageMeta} page={page} history={this.props.history} />} 
                        />
                    ))
                }
              {/* <Route exact path="/" component={MainContainer(HomePage)} />
              <Route path="/signup" component={MainContainer(Signup)} /> */}

              <Route exact path="/" component={NotFoundPage} />
            </Switch>

          </div>
          :
          <div>
            <Loading />
          </div>
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.appReducer
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(ActionCreators,dispatch)
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Routes);
