
let env = (window.location.href.includes('dev') || window.location.href.includes('tst')) ? 'development' : 'master';
let host, accessToken = ''; 

if(env === "master"){
    host = 'cdn.contentful.com';
    accessToken = '48QAaxDU6FXb6hKVNHfAZQ6hBq3xoH5ePClD3LcVOkg'
}else{
    host = 'preview.contentful.com';
    accessToken = 'b6Vy2ehz4s3VtdKse0H4-KWr6xT8affZ8W0IW7gO7gU'
}

export const conf = {
    creds: {
        accessToken: accessToken,
        space: '40v71zapbq14',
        host: host,
        environment: env,
        resolveLinks: true
    },
    websiteId: "OJinVPvvMRB0DpS25ApVH"

    // creds: {
    //     accessToken: 'AQLPahPFX9fx1xChUjrjcv_lgEc-XIl21qT-aM4St1M',
    //     space: 'bdplu37pikh2',
    //     host: 'preview.contentful.com',
    //     resolveLinks: true
    // },
    // websiteId: "5NJ6I7LoEHjPUsVebtnkXp"
}