import React from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {Link} from 'react-router-dom';
import Button from '../Button/Button';

const SimpleContent = props => {
    return (
      <div className="rich-text-block">
        <h2 className="heading-2" style={{maxWidth:'700px'}}>{props.content.header}</h2>
        <div className="content-container">
          <div className="common-text-line-height" dangerouslySetInnerHTML={{__html:documentToHtmlString(props.content.content)}}>
          </div>
          {
            props.content.button &&
            <Button 
              to={props.content.button.fields.linkToPage.fields.url} 
              color="#212529"
              pageMeta={props.pageMeta}
              title={props.content.button.fields.name} >
            </Button>
          }
        </div>
      </div>
    );
};

export default SimpleContent
