import {Col} from 'react-bootstrap';
import React from 'react';
import Comp from './Comp';

function Container(props) {
    return (
        <Col lg={`${props.columnGrid} ${props.containerClass}`} style={{padding:0}}>
            {
                 props.containers && props.containers.length > 0 &&
                 props.containers.map((cx,ix)=>(
                    cx.sys.contentType.sys.id == "container" ?
                     <Container 
                        openModal={props.openModal} 
                        key={Math.random(10)} 
                        columnGrid={cx.fields.columnGrid} 
                        containers={cx.fields.containers} 
                        containerClass={cx.fields.containerClass !== undefined ? cx.fields.containerClass :'turnkey-common'} 
                        isHome={props.isHome}ß
                        pageMeta={props.pageMeta}
                    />
                    :
                    <Comp 
                        openModal={props.openModal} 
                        key={Math.random(8)} data={cx}
                        isHome={props.isHome}
                        pageMeta={props.pageMeta}
                    />
                 ))
            }
        </Col>
    );
}

export default Container;


// props.containers.map((cxin,inx)=>(
//     <Container key={inx} containers={cxin.containers} />
// ))