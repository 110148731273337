import React from "react";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import FooterImage from "./FooterImage";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled from 'styled-components';
import _ from 'underscore'

const Button = styled.button`
    background: transparent;
    border: none;
    color: #FFF;`

const Footer = props => {

  function handleClick(k){
    if(window.location.hostname !== "www.amazon.com"){
      props.openModal(k)
    } else {
      const privacyPage = _.find(props.pageMeta,(pm)=>pm.pageName == "Privacy Policy");
      if(privacyPage){
        var win = window.open(privacyPage.url, '_blank');
        win.focus();
      } else{
        // Page is not defined
      }
    }
  }
  return ( 
    <>
      { props.isHome &&
        <FooterImage url={props.footer.background.fields.file.url} />
      }
      <div className="wrapper wrapper-footer">
        <div className="wrapper-inner">
          <footer className="footer clearfix">
            <div className="footer-text">
              <div className="privacy-terms desktop">
                {props.footer.kpo.map((k, i) => (
                  <Button key={i} onClick={()=>handleClick(k)} className="modal-link terms">
                    {k.fields.header}
                  </Button>
                ))}
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: documentToHtmlString(props.footer.infoText)
                }}
              ></p>

              <div className="equal-housing-logo housing-logo-right">
                <span className="svg-housing" aria-hidden="true">
                  {/* <img
                    style={{
                      position: "absolute",
                      right: "10px",
                      height: "50px"
                    }}
                    src={props.footer.logo.fields.file.url}
                    alt="Footer logo"
                  /> */}

                  <LazyLoadImage
                        style={{
                          position: "absolute",
                          right: "10px",
                          height: "50px"
                        }}
                        src={props.footer.logo.fields.file.url}
                        alt="Footer logo"
                  />

                </span>
                <div className="privacy-terms mobile">
                  {props.footer.kpo.map((k, i) => (
                    <Button onClick={()=>handleClick(k)} key={i} className="modal-link terms">
                      {k.fields.header}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Footer;
