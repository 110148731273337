import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './kpi.scss'

export const KPI = (props) => {
    return (
      <div className="three-column-cell">
        <div className="badge-with-text-container">
          <div className="badge-with-text-icon-wrapper">
            <span className="badge-with-text-mark">{props.kpi.badge}</span>
            <span className="badge-with-text-icon" aria-hidden="true">
              <LazyLoadImage alt={props.kpi.logo.fields.title} src={props.kpi.logo.fields.file.url} />
            </span>
          </div>
          <p className="badge-with-text-copy">
            {props.kpi.label}
          </p>
        </div>
      </div>
    );
}

